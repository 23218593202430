import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment, featureFlag } from './environments/environment';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import { API_URL } from './app/shared/service/api.constant';
import {
  PackageStorageInfoObj,
  SiteConfigObj,
} from './app/shared/service/site-config-service';

//
// set custom pdf worker path.
// This custom pdf worker is modified to enable digital signature in pdf.
//      More info: https://stackoverflow.com/questions/57380989/preview-pdf-file-with-e-signature-in-angular
//
// Download PDF worker js file from https://cdn.jsdelivr.net/npm/pdfjs-dist@{PDF_WORKER_VERSION_HERE}/legacy/build/pdf.worker.min.js
(<any>(
  window
)).pdfWorkerSrc = `/assets/scripts/pdf.worker.2.11.338.min.js`;
pdfjsLib.GlobalWorkerOptions.workerSrc = `/assets/scripts/pdf.worker.2.11.338.min.js`;

if (environment.production) {
  enableProdMode();
}

(async () => {
  // this api does not require authentication.
  const response = await fetch(
    environment.baseUrl + API_URL.site_config,
  );
  const config: SiteConfigObj = await response.json();

  featureFlag.page_title = config.page_title;
  featureFlag.memo_enable = config.memo_enable;
  featureFlag.econtract_enable = config.econtract_enable;
  featureFlag.ddoc = config.ddoc_enable;
  featureFlag.ddoc_enable_feature_26 = config.ddoc_enable_feature_26;
  featureFlag.ddoc_enable_feature_28 = config.ddoc_enable_feature_28;
  featureFlag.timestamp_memo_enable = config.timestamp_memo_enable;
  featureFlag.login_adfs_enable = config.login_adfs_enable;
  featureFlag.maintenance_mode = config.maintenance_mode;
  featureFlag.maintenance_message_th = config.maintenance_message_th;
  featureFlag.maintenance_message_en = config.maintenance_message_en;
  featureFlag.ndid = config.ndid_enable;
  featureFlag.sms_otp = config.sms_otp_enable;
  featureFlag.email_otp = config.email_otp_enable;
  featureFlag.thaicom = config.thaicom_enable;
  featureFlag.self_service_form = config.self_service_form_enable;
  featureFlag.google_client_id = config.google_client_id;
  featureFlag.export_memo_google_drive_enable =
    config.export_memo_google_drive_enable;
  featureFlag.contract_ddoc = config.contract_setting.ddoc_enable;
  featureFlag.contract_ddoc_enable_feature_26 =
    config.contract_setting.ddoc_enable;
  featureFlag.contract_ddoc_enable_feature_28 =
    config.contract_setting.ddoc_enable;
  featureFlag.contract_timestamp_memo_enable =
    config.contract_setting.timestamp_memo_enable;
  featureFlag.contract_sms_otp =
    config.contract_setting.sms_otp_enable;
  featureFlag.contract_email_otp =
    config.contract_setting.email_otp_enable;
  featureFlag.contract_ndid = config.contract_setting.ndid_enable;
  featureFlag.contract_thaicom =
    config.contract_setting.thaicom_enable;
  featureFlag.media_url_need_authentication =
    config.media_url_need_authentication;
  featureFlag.local_auth_enable = config.local_auth_enable;

  const packageStorageResponse = await fetch(
    environment.baseUrl + API_URL.package_storage_info,
  );
  const packageStorageInfo: PackageStorageInfoObj =
    await packageStorageResponse.json();
  featureFlag.storage_space_status = packageStorageInfo.storage_space;
  featureFlag.storage_subscription_status =
    packageStorageInfo.storage_subscription;
  featureFlag.package_subscription_status =
    packageStorageInfo.package_subscription;

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
})();
