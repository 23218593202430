import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from '../../../shared/service/api.constant';
import {
  CommentList,
  ComponentData,
  ComponentSessionConfig,
  InOutMemoListDetail,
  MemoDetail,
  MemoListDetail,
  SealedOutgoingMemo,
} from '../model/memo.model';
import { concatMap, map } from 'rxjs/operators';
import { Memo } from 'src/app/store/memo/memo.model';

@Injectable({
  providedIn: 'root',
})
export class MemoService {
  headers: HttpHeaders;
  historyText: string;
  loaList: any;
  private setLoa = new Subject();
  data = this.setLoa.asObservable();

  changeTable: any;
  private setTable = new Subject();
  tableData = this.setTable.asObservable();
  requestPo: Observable<any>;
  uploadedPdfMemoType = ['upload', 'outgoing', 'incoming'];

  private setFormat = new Subject();
  inputDateFormat = this.setFormat.asObservable();

  private setWidthForDate = new Subject();
  widthForDate = this.setWidthForDate.asObservable();

  constructor(private http: ApiService) {}

  isAppleDevice(): boolean {
    return /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent);
  }

  isMac(): boolean {
    return /(Mac)/i.test(navigator.userAgent);
  }

  isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  isIosPhonePreview(memo): boolean {
    return (
      this.isAppleDevice() &&
      !(this.isMac() && !this.isSafari()) &&
      this.uploadedPdfMemoType.includes(memo.memo_type) &&
      memo.preview_document
    );
  }

  getPdfUrl(memo: MemoDetail | undefined): string {
    if (!memo) {
      return '';
    }
    if (memo?.memo_type === 'outgoing') {
      if (memo?.inout_status === 'completed') {
        return memo?.sealed_document?.imported_file;
      } else if (memo?.status === 'draft') {
        return memo.outgoing.uploaded_pdf;
      }
    }
    return this.isIosPhonePreview(memo)
      ? memo.preview_document
      : memo.signed_document;
  }

  getDepartmentList(data?: { [type: string]: string }) {
    return this.http.get(API_URL.departments, data);
  }

  previewMemoPDF(data: { [type: string]: string }) {
    return this.http.pdfPost(API_URL.memo_preview, data);
  }

  // My task
  getMyTaskList(params?: { [type: string]: string }) {
    return this.http.get(API_URL.my_task, params);
  }

  getMyTaskBadge(params?: { [type: string]: string }) {
    return this.http.get(API_URL.my_task + 'badge/', params);
  }

  getMyTaskListReview(params?: { [type: string]: string }) {
    return this.http.get(API_URL.my_task_review, params);
  }

  /**
   * Get the unique sessions property from `ComponentData`.
   * If all components is undefined a session then it return empty array.
   */
  getInputComponentSessions(
    inputComponents: ComponentData[],
    ignoreUndefinedGroup = true,
  ): ComponentSessionConfig[] {
    const sessionNames: string[] = [];
    const sessions: ComponentSessionConfig[] = [];
    const retrieveSessionName = (
      session: string | ComponentSessionConfig,
    ) => {
      if (typeof session === 'object') {
        return session.sessionName;
      }
      return session;
    };
    inputComponents.forEach((component) => {
      const sessionName = retrieveSessionName(component.session);
      if (ignoreUndefinedGroup && !sessionName) {
        return;
      }
      const isDuplicated = sessionNames.includes(sessionName);
      if (!isDuplicated && component.componentName != null) {
        sessionNames.push(sessionName);
        sessions.push(this.rewrapSessionConfig(component.session));
      }
    });
    return sessions;
  }

  // RealMemo
  getGeneralMemoList(params?: {
    [type: string]: string;
  }): Observable<MemoListDetail> {
    return this.http.get<MemoListDetail>(
      API_URL.general_memo,
      params,
    );
  }

  getMemoListCSV(data) {
    // url นี้จะไม่ return file อีกแล้ว แต่จะ return celery task id มาแทน
    return this.http.get(API_URL.general_memo + 'excel/', data);
  }

  getMemoFiles(data) {
    return this.http.getBlob(
      API_URL.general_memo + 'download-multiple-pdf/',
      data,
    );
  }

  getMemoReferenceById(id) {
    return this.http.get(API_URL.memos + id + '/references/');
  }

  updateMemoReferenceById(id, data) {
    return this.http.post(
      API_URL.memos + id + '/update-references/',
      data,
    );
  }

  // Action
  createMemo(data: any): Observable<MemoDetail> {
    return this.http.post<MemoDetail>(API_URL.memos, data).pipe(
      // upload file for in-out type
      concatMap((res) => {
        return this.chainUploadInOutBlob<MemoDetail>(data, res);
      }),
    );
  }

  updateMemo(id, data): Observable<MemoDetail> {
    return this.http
      .patch<MemoDetail>(API_URL.memos + id + '/', data)
      .pipe(
        // upload file for incoming type
        concatMap((res) => {
          return this.chainUploadInOutBlob<MemoDetail>(data, res);
        }),
      );
  }

  updateAnnouncement(id, data, header?) {
    return this.http.post(
      API_URL.memos + id + '/announcement/',
      data,
      header,
    );
  }

  updateAnnouncementPeople(id, data, header?) {
    return this.http.post(
      API_URL.memos + id + '/announcement-people/',
      data,
      header,
    );
  }

  publishMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/publish/', data);
  }

  extendMemo(id, data) {
    return this.http.post(API_URL.memos + id + '/extend/', data);
  }

  reviseMemo(id) {
    return this.http.post(API_URL.memos + id + '/revise/', {});
  }

  recallMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/recall/', data);
  }

  approveMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/approve/', data);
  }

  rejectMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/reject/', data);
  }

  terminateMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/terminate/', data);
  }

  reviewMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/review/', data);
  }

  completeReviewMemo(id, data?) {
    return this.http.post(
      API_URL.memos_review + id + '/complete/',
      data,
    );
  }

  downloadMemo(id, data?) {
    return this.http.postResponseBlob(
      API_URL.memos + id + '/download-pdf/',
      data,
    );
  }

  getMemoDetail(id: number): Observable<MemoDetail> {
    return this.http.get(API_URL.memos + id + '/');
  }

  getMemoDetailReview(id: number): Observable<MemoDetail> {
    return this.http.get(API_URL.memos_review + id + '/');
  }

  getMemoHistory(params?: { [type: string]: string }) {
    return this.http.get(API_URL.memos_history, params);
  }

  getHistoryLogCSV(data) {
    return this.http.getBlob(API_URL.memos_history + 'excel/', data);
  }

  deleteMemo(id) {
    return this.http.delete(API_URL.memos + id + '/');
  }

  // Upload Blob

  uploadBlob(id, data) {
    return this.http.patch(API_URL.upload_memo_blob + id + '/', data);
  }
  uploadContractBlob(id, data) {
    return this.http.patch(
      API_URL.upload_contract_blob + id + '/',
      data,
    );
  }

  uploadMultiBlob(id, data) {
    return this.http.patch(
      API_URL.upload_memo_blob_multi + id + '/',
      data,
    );
  }

  uploadContractMultiBlob(id, data) {
    return this.http.patch(
      API_URL.upload_contract_blob_multi + id + '/',
      data,
    );
  }

  removeBlob(data) {
    return this.http.post(API_URL.remove_memo_blob, data);
  }

  removeContractBlob(data) {
    return this.http.post(API_URL.remove_contract_blob, data);
  }

  // Comment
  private getCommentUrl(isInOut = false): string {
    return isInOut
      ? API_URL.memo_inout_comment
      : API_URL.memo_comment;
  }

  getCommentList(params, isInOut = false): Observable<CommentList[]> {
    return this.http.get(this.getCommentUrl(isInOut), params);
  }

  createNewComment(data, isInOut = false): Observable<CommentList> {
    return this.http.post(this.getCommentUrl(isInOut), data);
  }

  updateMemoRead(data) {
    return this.http.post(API_URL.memo_read, data);
  }

  deleteComment(id, isInOut = false) {
    return this.http.delete(this.getCommentUrl(isInOut) + id + '/');
  }

  // Attachment
  private getAttachmentUrl(isInOut = false): string {
    return isInOut
      ? API_URL.memo_inout_attachment
      : API_URL.memo_attachment;
  }

  getMemoAttachment(
    params?: { [type: string]: string },
    isInOut = false,
  ) {
    return this.http.get(this.getAttachmentUrl(isInOut), params);
  }

  removeMemoAttachment(id, isInOut = false) {
    return this.http.delete(
      this.getAttachmentUrl(isInOut) + id + '/',
    );
  }

  updateMemoAttchment(id, data, isInOut = false) {
    return this.http.patch(
      this.getAttachmentUrl(isInOut) + id + '/',
      data,
    );
  }

  uploadMemoAttachment(data, isInOut = false) {
    return this.http.post(
      this.getAttachmentUrl(isInOut) + 'bulk-create/',
      data,
    );
  }

  downloadMemoAttachment(id, data?, isInOut = false) {
    return this.http.post(
      this.getAttachmentUrl(isInOut) + id + '/download/',
      data,
    );
  }

  logDownloadFile(
    id,
    log_type: 'rejected' | 'attachment' | 'comment',
  ) {
    return this.http.post(
      API_URL.memos + id + '/log-download-file/',
      { log_type },
    );
  }

  // Verify Duplicated Memo Number
  verifyMemoNumber(params) {
    return this.http.get(API_URL.memo_number_verify, params);
  }

  printFile(url) {
    return this.http.printFile(url);
  }

  getMemoTypes() {
    const params = { type: 'memo_type' };
    return this.http.get(API_URL.dropdown, params);
  }

  getMemoUploadTypes() {
    const params = { type: 'memo_upload_type' };
    return this.http.get(API_URL.dropdown, params);
  }

  setLoadLoaList(department) {
    this.loaList = department;
    this.setLoa.next(this.loaList);
  }

  setInputDate(date) {
    this.setFormat.next(date);
  }

  setWidthDate(event?) {
    this.setWidthForDate.next(event);
  }

  getMemoRevised(params) {
    return this.http.get(API_URL.memo_revised, params);
  }

  // acknowledgement
  getAcknowledge(params) {
    return this.http.get('/api/acknowledges/', params);
  }

  getAcknowledgeCSV(data) {
    return this.http.getBlob('/api/acknowledges/excel/', data);
  }

  // download file
  createDownloadFile(data: any, filename: string): void {
    // for IE10+
    const blob = new Blob([data], { type: data.type });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  rewrapSessionConfig(
    session: string | ComponentSessionConfig,
  ): ComponentSessionConfig {
    if (typeof session === 'string') {
      return { sessionName: session };
    }
    return session;
  }

  updateMemoDetail(id, data) {
    return this.http.patch(API_URL.memo_detail + id + '/', data);
  }

  updateMemoTerminate(id, data) {
    return this.http.post(
      API_URL.memos + id + '/admin-custom-terminate-memo/',
      data,
    );
  }

  updateMemoReject(id, data) {
    return this.http.post(
      API_URL.memos + id + '/admin-custom-reject-memo/',
      data,
    );
  }

  updateMemoExpired(id, data) {
    return this.http.post(
      API_URL.memos + id + '/admin-custom-extend/',
      data,
    );
  }

  // trash
  getFinishedMemoList(params?: {
    [type: string]: string;
  }): Observable<MemoListDetail> {
    return this.http.get<MemoListDetail>(
      API_URL.finished_memo,
      params,
    );
  }

  bulkTrashMemo(data) {
    return this.http.post(API_URL.bulk_trash_memo, data);
  }

  bulkUntrashMemo(data) {
    return this.http.post(API_URL.bulk_untrash_memo, data);
  }

  bulkPermanentlyDeleteMemo(data) {
    return this.http.post(API_URL.bulk_permanently_delete_memo, data);
  }

  getFinishedMemoListCSV(data) {
    return this.http.get(API_URL.finished_memo + 'excel/', data);
  }

  exportMemoToGDrive(data) {
    return this.http.post(
      API_URL.finished_memo + 'export-gdrive/',
      data,
    );
  }
  getNdidCredit() {
    return this.http.get('/api/ndid/credit/');
  }

  getThaicomCredit() {
    return this.http.get('/api/thaicom/credit/');
  }

  getInOutMemoList(params?: {
    [type: string]: string;
  }): Observable<InOutMemoListDetail> {
    return this.http.get<InOutMemoListDetail>(
      API_URL.inout_memo,
      params,
    );
  }

  uploadInOutBlob(id, data, type: 'in' | 'out' = 'in') {
    let path;
    if (type === 'in') {
      path = API_URL.upload_incoming_blob;
    } else if (type === 'out') {
      path = API_URL.upload_outgoing_blob;
    } else {
      throw Error('should be "in" or "out"');
    }
    return this.http.patch(path + id + '/', data);
  }

  chainUploadInOutBlob<T extends MemoDetail>(
    data: any,
    res: T,
  ): Observable<T> {
    if (!data?.uploaded_pdf_file) {
      return of(res);
    }

    let inoutId;
    let apiType: 'in' | 'out';
    if (data?.memo_type === 'incoming') {
      inoutId = res?.incoming?.id;
      apiType = 'in';
    } else if (data?.memo_type === 'outgoing') {
      inoutId = res?.outgoing?.id;
      apiType = 'out';
    } else {
      return of(res);
    }

    const uploadFile = data?.uploaded_pdf_file as File;
    const fd = new FormData();
    fd.append('uploaded_pdf', uploadFile);
    fd.append('uploaded_pdf_name', uploadFile.name);
    return this.uploadInOutBlob(inoutId, fd, apiType).pipe(
      map(() => res),
    );
  }

  getAssignAssigneeTaskList(params?: { [type: string]: string }) {
    return this.http.get(API_URL.assign_assignee_task, params);
  }

  getAssignAssigneeBadge(params?: { [type: string]: string }) {
    return this.http.get(
      API_URL.assign_assignee_task + 'badge/',
      params,
    );
  }

  getActionRequireTaskList(params?: { [type: string]: string }) {
    return this.http.get(API_URL.action_require_task, params);
  }

  getOutgoingApprovalTaskList(params?: { [type: string]: string }) {
    return this.http.get(API_URL.outgoing_approval_task, params);
  }

  getOutgoingApprovalBadge(params?: { [type: string]: string }) {
    return this.http.get(
      API_URL.outgoing_approval_task + 'badge/',
      params,
    );
  }

  getActionRequireBadge(params?: { [type: string]: string }) {
    return this.http.get(
      API_URL.action_require_task + 'badge/',
      params,
    );
  }

  getInOutAnnouncementBadge(): Observable<{ need_ack: number }> {
    return this.http.get('/api/in-out-announcement/badge/');
  }

  assigneeResendNotify(memo_id, loa_member_id) {
    return this.http.post(
      API_URL.memos + memo_id + '/resend-notify/',
      { loa_member_id },
    );
  }

  sealOutgoingMemo(memoId, data) {
    return this.http.post(
      API_URL.memos + memoId + '/seal-outgoing-document/',
      data,
    );
  }

  deleteSealOutgoingMemo(memoId) {
    return this.http.delete(
      API_URL.memos + memoId + '/seal-outgoing-document/',
    );
  }

  getSealOutgoingMemo(memoId): Observable<SealedOutgoingMemo> {
    return this.http.get(API_URL.memos + memoId).pipe(
      map((res: MemoDetail) => {
        return res?.sealed_document ?? null;
      }),
    );
  }

  saveInComingComment(memo_id, comment: string) {
    return this.http.post(
      API_URL.memos + memo_id + '/save-comment/',
      { comment },
    );
  }

  isDuplicateMemoNumber(data: {
    memo_number: string;
    self_id?: number;
  }): Observable<{ duplicate: boolean }> {
    return this.http.post(
      API_URL.general_memo + 'check-duplicate/',
      data,
    );
  }

  getPreviewPage(memo_id, page): Observable<File> {
    return this.http
      .postResponseBlob(
        API_URL.general_memo + memo_id + '/preview-page/',
        { page },
      )
      .pipe(
        map(
          (blob: Blob) =>
            new File([blob], 'preview.png', { type: 'image/png' }),
        ),
      );
  }

  removeAllMemoAttachment(memo_id) {
    return this.http.post('/api/memo-attachments/remove-all/', {
      memo_id,
    });
  }

  validateFiles(data) {
    return this.http.post('/api/validate-files/', data);
  }

  getContractList(params?: {
    [type: string]: string;
  }): Observable<MemoListDetail> {
    return this.http.get<MemoListDetail>(API_URL.contract, params);
  }
  getContractListCSV(data) {
    return this.http.get(API_URL.contract + 'excel/', data);
  }

  getContractFiles(data) {
    return this.http.getBlob(
      API_URL.contract + 'download-multiple-pdf/',
      data,
    );
  }

  getRequestEvidence(id, data, header?) {
    return this.http.post(
      '/api/memos/contract/' + id + '/request-evidences/',
      data,
      header,
    );
  }
  getSelfAttachment(id, data, header?) {
    return this.http.post(
      '/api/memos/contract/' + id + '/self-request-attachments/',
      data,
      header,
    );
  }

  uploadRequestAttachment(id, data, header?): Observable<any> {
    return this.http.post(
      '/api/memos/contract/' +
        id +
        '/self-upload-request-attachments/',
      data,
      header,
    );
  }

  uploadSelfieVideo(id, fd: FormData, header?): Observable<any> {
    return this.http.post(
      '/api/memos/contract/' + id + '/self-upload-selfie-video/',
      fd,
      header,
    );
  }
  testConvertAndResizeVideo(fd: FormData, header?): Observable<any> {
    return this.http.postResponseBlob(
      '/api/memos/contract/test-resize-convert-video/',
      fd,
      header,
    );
  }
  getEvidence(id) {
    return this.http.get('/api/memos/contract/' + id + '/evidence/');
  }

  getVideoSelfie(id, data) {
    return this.http.post(
      '/api/memos/contract/' + id + '/selfie-video/',
      data,
    );
  }

  getEvidenceAttachment(id, data) {
    return this.http.post(
      '/api/memos/contract/' + id + '/request-attachments/',
      data,
    );
  }

  postResubmit(id, data) {
    return this.http.post(
      '/api/memos/contract/' + id + '/resubmit-request/',
      data,
    );
  }

  confirmEvidence(id, data) {
    return this.http.post(
      '/api/memos/contract/' + id + '/confirm-evidence/',
      data,
    );
  }

  checkCredit(id, data) {
    return this.http.post(
      '/api/memos/contract/' + id + '/check-credit/',
      data,
    );
  }
}
