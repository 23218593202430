<div class="d-flex"
    id="wrapper"
    style="max-height: 100vh; max-height: 100dvh;"
    *ngIf="navbarActive">
  <div class="position-relative">
    <div class="position-absolute position-logo">
      <div class="mx-2"
          [ngStyle]="{'color': themeList?.text_color}">
        <i class="pointer custom-hamburger fa fa-bars mt-3 hamburger-pc"
            aria-hidden="true"
            (click)="isFullSidebar = !isFullSidebar;"></i>
        <i class="pointer custom-hamburger fa fa-bars mt-3 hamburger-mobile"
            *ngIf="!openSidebar"
            aria-hidden="true"
            (click)="openSidebar = !openSidebar; isFullSidebar = true;"></i>
      </div>

      <div class="flex-grow-1 ml-4 logo-none-mobile">
        <img [src]="themeList?.display_sidebar? (themeList.display_sidebar | secure | async): 'assets/images/codium_logo.png' "
            style="max-height: 50px;"
            class="pl-3">
      </div>
    </div>

  </div>
  <!-- Sidebar -->
  <div class="width-sidebar"
      id="sidebar-wrapper"
      [ngClass]="{'position-sidebar':openSidebar,'hidden-sidebar':!openSidebar,
      'width-sidebar-full': !isFullSidebar}"
    [ngStyle]="{
        background:
          'linear-gradient(to top, ' +
          themeList?.bg_color_primary +
          ', ' +
          themeList?.bg_color_secondary +
          ' 60%)',
        color: themeList?.text_color
      }"
    (window:resize)="onResize($event)">
    <div class="sidebar-heading d-flex">
      <div class="pointer mx-2 position-relative"
        [ngClass]="openSidebar? 'd-none':''"
        [ngStyle]="{'color': themeList?.text_color, 'min-width': isFullSidebar? '0':'50px'}">
        <!-- <i class="custom-hamburger position-burger fa fa-bars"
            [ngStyle]="{'right': isFullSidebar? '-20px':'10px'}"
            aria-hidden="true"
            (click)="openFullSidebar()"></i> -->
      </div>
      <div class="flex-grow-1"
        [ngClass]="!isFullSidebar? 'ml-0':'ml-3'"
        style="height: 40px;">
        <img [src]="themeList?.display_sidebar? (themeList.display_sidebar | secure | async): 'assets/images/codium_logo.png' "
          style="max-height: 50px;"
          class="show-logo-mobile">
      </div>
      <span class="show-close pointer pr-4 text-right"
        (click)="openSidebar = !openSidebar; isFullSidebar = false;"><i class="fas fa-times"></i></span>
    </div>

    <!-- /#sidebar-wrapper -->
    <div class="list-group list-group-flush pt-4"
      [ngClass]="isFullSidebar ? 'text-left':'text-center'">

      <!-- standard sidebar tab-->
      <div *ngIf="!isInOutRoute"
        class="menu-main overflow-auto"
        [style.--padding]="(isFullSidebar? '1.5':'0.5') + 'rem'">
        <div class="custom-border">
          <!-- News Feed -->
          <div routerLinkActive="active"
            [routerLink]="['/', 'news-feed']"
            class="mt-2"
            #rla="routerLinkActive"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            ngbTooltip="{{'SIDEBAR.NEWS-FEED' | translate}}"
            placement="right">
            <a class="list-group-item list-group-item-action px-menu-4">
              <svg-icon src="assets/svg/icon-menu/blog.svg"
                [svgStyle]="{
                  'fill': rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
              </svg-icon>
              <span class="pl-2 f-size-17"
                *ngIf="isFullSidebar"
                [ngStyle]="{
                  color: rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              {{'SIDEBAR.NEWS-FEED'|translate}}
            </span>
            </a>
          </div>
        </div>

        <div class="custom-border"
          *appCheckPermission="['General', 'Administrator', 'Official Letter Admin']"
          [ngStyle]="{'background': '' , 'color': themeList?.text_color}">
          <!-- Approval group header -->
          <div class="mt-2 hasSubMenu"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            placement="right"
            ngbTooltip="{{'SIDEBAR.PENDING'|translate}}"
            (click)="expandApprovalGroup=!expandApprovalGroup">
            <a class="list-group-item list-group-item-action px-menu-4 position-relative">
              <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                class="mt-title-svg"
                [stretch]="true"
                [svgStyle]="{
                'fill': themeList?.text_color,
                'height.px': 20,
                'width.px': 20
              }">
              </svg-icon>
              <span class="w-100 f-size-17 pt-1 pl-2"
                *ngIf="isFullSidebar"
                [ngStyle]="{'color': themeList?.text_color}">{{"SIDEBAR.PENDING"|translate}}</span>
              <div class="text-right pl-2 position-absolute position-arrow"
                [ngStyle]="{'color': themeList?.text_color}">
                <i class="fal"
                  [ngClass]=" expandApprovalGroup ? 'fa-angle-up' : 'fa-angle-down'"></i>
              </div>
            </a>
          </div>

          <ng-container *ngIf="expandApprovalGroup">
            <!-- Overdue -->
            <div [class.active]="isPendingApprovalOverDueRoute"
              [routerLink]="['/', 'pending-approval']"
              [queryParams]="{type: 'overdue'}"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              *appCheckPermission="['General', 'Administrator', 'Official Letter Admin']"
              ngbTooltip="Overdue"
              placement="right">
              <a class="list-group-item list-group-item-action notification px-menu-4">
                <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                  [stretch]="true"
                  [svgStyle]="{
                  'fill': isPendingApprovalOverDueRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{
                  color: isPendingApprovalOverDueRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              Overdue
            </span>
                <span class="badge-number d-inline-block"
                  *ngIf="notificationService.overDueCount > 0">
              {{ notificationService.overDueCount > 99 ? '99+' : notificationService.overDueCount }}
            </span>
              </a>
            </div>

            <!-- Due by Today -->
            <div [class.active]="isPendingApprovalTodayDueRoute"
              [routerLink]="['/', 'pending-approval']"
              [queryParams]="{type: 'today'}"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              *appCheckPermission="['General', 'Administrator', 'Official Letter Admin']"
              ngbTooltip="Due by Today"
              placement="right">
              <a class="list-group-item list-group-item-action notification px-menu-4">
                <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                  [stretch]="true"
                  [svgStyle]="{
                  'fill': isPendingApprovalTodayDueRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{
                  color: isPendingApprovalTodayDueRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              Due by Today
            </span>
                <span class="badge-number d-inline-block"
                  *ngIf="notificationService.todayDueCount > 0">
              {{ notificationService.todayDueCount > 99 ? '99+' : notificationService.todayDueCount }}
            </span>
              </a>
            </div>

            <!-- Next Due -->
            <div [class.active]="isPendingApprovalNextDueRoute"
              [routerLink]="['/', 'pending-approval']"
              [queryParams]="{type: 'nextdue'}"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              *appCheckPermission="['General', 'Administrator', 'Official Letter Admin']"
              ngbTooltip="Next Due"
              placement="right">
              <a class="list-group-item list-group-item-action notification px-menu-4">
                <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                  [stretch]="true"
                  [svgStyle]="{
                  'fill': isPendingApprovalNextDueRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{
                  color: isPendingApprovalNextDueRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              Next Due
            </span>
                <span class="badge-number d-inline-block"
                  *ngIf="notificationService.nextDueCount > 0">
              {{ notificationService.nextDueCount > 99 ? '99+' : notificationService.nextDueCount }}
            </span>
              </a>
            </div>
          </ng-container>
        </div>

        <!-- Pendding-Review -->
        <div routerLinkActive="active"
          [routerLink]="['/', 'waiting-review']"
          #rla="routerLinkActive"
          [style.--color]="themeList?.hovered_text_color"
          [style.--bg]="themeList?.hovered_text_color + '20'"
          *appCheckPermission="['General', 'Administrator', 'Official Letter Admin']"
          ngbTooltip="{{'APPROVAL.REVIEW-REQUEST' | translate}}"
          placement="right">
          <a class="list-group-item list-group-item-action notification px-4">
            <svg-icon src="assets/images/icons/pending-review.svg"
              [stretch]="true"
              [svgStyle]="{
                  'fill': rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
            </svg-icon>
            <span class="pl-2 f-size-17"
              *ngIf="isFullSidebar"
              [ngStyle]="{
                  color: rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              {{'APPROVAL.REVIEW-REQUEST'|translate}}
            </span>
            <span class="badge-number d-inline-block"
              *ngIf="notificationService.memoNotificationReviewCount > 0">
              {{ notificationService.memoNotificationReviewCount > 99 ? '99+' : notificationService.memoNotificationReviewCount }}
            </span>
          </a>
        </div>

        <!-- Management -->
        <div class="custom-border custom-border-top"
          *appCheckPermission="['Admin', 'Master Admin', 'IT']"
          [ngStyle]="{'background': departmentSubMenu? '': '' , 'color': themeList?.text_color}">
          <div routerLinkActive="active"
            #rla="routerLinkActive"
            class="mt-2 hasSubMenu"
            *appCheckPermission="['Admin', 'Master Admin', 'IT']"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            placement="right"
            ngbTooltip="{{'SIDEBAR.MANAGEMENT'|translate}}"
            (click)="departmentSubMenu=!departmentSubMenu">
            <a class="list-group-item list-group-item-action px-menu-4 position-relative ">
              <svg-icon src="assets/svg/icon-menu/org.svg"
                class="mt-title-svg"
                [stretch]="true"
                [svgStyle]="{
                    'fill': rla?.isActive
                      ? themeList?.hovered_text_color
                      : themeList?.text_color,
                    'height.px': 20,
                    'width.px': 20
                  }">
              </svg-icon>
              <span class="w-100 f-size-17 pt-1 pl-2"
                *ngIf="isFullSidebar"
                [ngStyle]="{'color': themeList?.text_color}">{{'SIDEBAR.MANAGEMENT'|translate}}
              </span>
              <div class="text-right pl-2 position-absolute position-arrow"
                [ngStyle]="{'color': themeList?.text_color}">
                <i class="fal"
                  [ngClass]=" departmentSubMenu ? 'fa-angle-up' : 'fa-angle-down'"></i>
              </div>
            </a>
          </div>

          <!-- Department level -->
          <ng-container *ngIf="departmentSubMenu">
            <div routerLinkActive="active"
              [routerLink]="['/', 'department', 'level']"
              #rla="routerLinkActive"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              *appCheckPermission="['Admin', 'Master Admin', 'IT']"
              ngbTooltip="{{'SIDEBAR.DEPARTMENT-LEVEL' | translate}}"
              placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">

                <svg-icon src="assets/svg/icon-menu/department-level.svg"
                  [stretch]="true"
                  [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.DEPARTMENT-LEVEL' | translate}}
                </span>
              </a>
            </div>

            <!-- Department -->
            <div routerLinkActive="active"
              [routerLink]="['/', 'department', 'add']"
              #rla="routerLinkActive"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              *appCheckPermission="['Admin', 'Master Admin', 'IT']"
              ngbTooltip="{{'SIDEBAR.DEPARTMENT' | translate}}"
              placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <!-- Department -->
                <svg-icon src="assets/svg/icon-menu/events.svg"
                  [stretch]="true"
                  [svgStyle]="{ 'fill': (rla.isActive ?
                        themeList?.hovered_text_color: themeList?.text_color), 'height.px': 20, 'width.px': 20}">
                </svg-icon>
                <span class="pl-2 f-size-17"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.DEPARTMENT' | translate}}
                </span>
              </a>
            </div>

            <!-- Manage Users -->
            <div routerLinkActive="active"
              [routerLink]="['/', 'users']"
              #rla="routerLinkActive"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              *appCheckPermission="['Admin', 'Master Admin', 'IT']"
              ngbTooltip="{{'SIDEBAR.MANAGE-USERS' | translate}}"
              placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <!-- Manage Users -->
                <svg-icon src="assets/svg/icon-menu/user--profile.svg"
                  [stretch]="true"
                  [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.MANAGE-USERS'|translate}}
                </span>
              </a>
            </div>

            <!-- LOA -->
            <ng-container *appFeatureFlag="'memo_enable'">
              <div routerLinkActive="active"
                  [routerLink]="['/', 'loa']"
                  #rla="routerLinkActive"
                  [style.--color]="themeList?.hovered_text_color"
                  [style.--bg]="themeList?.hovered_text_color + '20'"
                  *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                  ngbTooltip="{{'SIDEBAR.MANAGE-APPROVAL' | translate}}"
                  placement="right">
                <a class="list-group-item list-group-item-action px-menu-4">
                  <svg-icon src="assets/svg/icon-menu/category.svg"
                      [stretch]="true"
                      [svgStyle]="{
                        'fill': rla.isActive
                          ? themeList?.hovered_text_color
                          : themeList?.text_color,
                        'height.px': 20,
                        'width.px': 20
                      }">
                  </svg-icon>
                  <span class="pl-2 f-size-17"
                      *ngIf="isFullSidebar"
                      [ngStyle]="{
                        color: rla.isActive
                          ? themeList?.hovered_text_color
                          : themeList?.text_color
                      }">
                    {{'SIDEBAR.MANAGE-APPROVAL'|translate}}
                  </span>
                </a>
              </div>
            </ng-container>

            <!-- CC Group -->
            <div routerLinkActive="active"
              [routerLink]="['/', 'cc-group']"
              #rla="routerLinkActive"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              *appCheckPermission="['Admin', 'Master Admin', 'IT']"
              ngbTooltip="{{'SIDEBAR.MANAGE-CC-GROUP' | translate}}"
              placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <svg-icon src="assets/svg/icon-menu/cc.svg"
                  [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.MANAGE-CC-GROUP'|translate}}
                </span>
              </a>
            </div>

            <!-- Memo List -->
            <div routerLinkActive="active"
              [routerLink]="['/', 'additional-memos']"
              #rla="routerLinkActive"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              *appCheckPermission="['Admin', 'Master Admin']"
              ngbTooltip="{{'SIDEBAR.MEMO-LIST' | translate}}"
              placement="right">
              <a class="list-group-item list-group-item-action px-4">
                <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                  [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.MEMO-LIST'|translate}}
                </span>
              </a>
            </div>

            <!-- All Document (Trash) -->
            <div routerLinkActive="active"
              [routerLink]="['/', 'memos', 'trash']"
              #rla="routerLinkActive"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              *appCheckPermission="['Admin', 'Master Admin']"
              ngbTooltip="{{'SIDEBAR.DELETE-MEMO' | translate}}"
              placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                  [stretch]="true"
                  [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.DELETE-MEMO'|translate}}
                </span>
              </a>
            </div>
            <!-- upload memo type -->
            <ng-container *appFeatureFlag="'memo_enable'">
              <div routerLinkActive="active"
                  [routerLink]="['/', 'manage-types-upload-memo', 'type']"
                  #rla="routerLinkActive"
                  [style.--color]="themeList?.hovered_text_color"
                  [style.--bg]="themeList?.hovered_text_color + '20'"
                  *appCheckPermission="['Admin', 'Master Admin']"
                  ngbTooltip="{{'SIDEBAR.UPLOAD-MEMO-TYPE' | translate}}"
                  placement="right">
                <a class="list-group-item list-group-item-action px-menu-4">
                  <svg-icon src="assets/svg/icon-menu/catalog.svg"
                      [stretch]="true"
                      [svgStyle]="{ 'fill': (rla.isActive ?
                        themeList?.hovered_text_color: themeList?.text_color), 'height.px': 20, 'width.px': 20}">
                  </svg-icon>
                  <span class="pl-2 f-size-17"
                      *ngIf="isFullSidebar"
                      [ngStyle]="{
                          color: rla.isActive
                          ? themeList?.hovered_text_color
                          : themeList?.text_color
                      }">
                    {{'SIDEBAR.UPLOAD-MEMO-TYPE' | translate}}
                  </span>
                </a>
              </div>
            </ng-container>

            <!-- upload In-Out type -->
            <div routerLinkActive="active"
              [routerLink]="['/', 'manage-types-inout-memo', 'type']"
              #rla="routerLinkActive"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              *appCheckPermission="['Admin', 'Master Admin']"
              ngbTooltip="Department name and code"
              placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <svg-icon src="assets/svg/icon-menu/catalog.svg"
                  [stretch]="true"
                  [svgStyle]="{ 'fill': (rla.isActive ?
                      themeList?.hovered_text_color: themeList?.text_color), 'height.px': 20, 'width.px': 20}">
                </svg-icon>
                <span class="pl-2 f-size-17"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{
                        color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  Department name and code
                </span>
              </a>
            </div>
          </ng-container>
        </div>

        <!-- Certificate -->
<!--        <ng-container *appFeatureFlag="'ddoc|contract_ddoc'">-->
<!--          <div class="custom-border"-->
<!--              *appCheckPermission="['Admin', 'Master Admin', 'IT']"-->
<!--              [ngStyle]="{'background': manageCertificate? '': '' , 'color': themeList?.text_color}">-->
<!--            &lt;!&ndash; manage certificate &ndash;&gt;-->
<!--            <div routerLinkActive="active"-->
<!--                class="mt-2 hasSubMenu"-->
<!--                #rla="routerLinkActive"-->
<!--                *appCheckPermission="['Admin', 'Master Admin', 'IT']"-->
<!--                [style.&#45;&#45;color]="themeList?.hovered_text_color"-->
<!--                [style.&#45;&#45;bg]="themeList?.hovered_text_color + '20'"-->
<!--                placement="right"-->
<!--                ngbTooltip="{{'CSR.CERTIFICATE'|translate}}"-->
<!--                (click)="manageCertificate=!manageCertificate">-->
<!--              <a class="list-group-item list-group-item-action px-menu-4 position-relative">-->
<!--                <svg-icon src="assets/svg/icon-menu/document.svg"-->
<!--                    class="mt-title-svg"-->
<!--                    [stretch]="true"-->
<!--                    [svgStyle]="{-->
<!--                'fill': rla?.isActive-->
<!--                  ? themeList?.hovered_text_color-->
<!--                  : themeList?.text_color,-->
<!--                'height.px': 20,-->
<!--                'width.px': 20-->
<!--              }">-->
<!--                </svg-icon>-->
<!--                <span class="w-100 f-size-17 pt-1 pl-2"-->
<!--                    *ngIf="isFullSidebar"-->
<!--                    [ngStyle]="{'color': themeList?.text_color}">{{'CSR.CERTIFICATE'|translate}}</span>-->
<!--                <div class="text-right pl-2 position-absolute position-arrow "-->
<!--                    [ngStyle]="{'color': themeList?.text_color}">-->
<!--                  <i class="fal"-->
<!--                      [ngClass]=" manageCertificate ? 'fa-angle-up' : 'fa-angle-down'"></i>-->
<!--                </div>-->
<!--              </a>-->
<!--            </div>-->

<!--            &lt;!&ndash; manage csr &ndash;&gt;-->
<!--            <ng-container *ngIf="manageCertificate">-->
<!--              <ng-container *appFeatureFlag="'ddoc_enable_feature_28|contract_ddoc_enable_feature_28'">-->
<!--                <div routerLinkActive="active"-->
<!--                    [routerLink]="['/', 'manage-csr']"-->
<!--                    #rla="routerLinkActive"-->
<!--                    [style.&#45;&#45;color]="themeList?.hovered_text_color"-->
<!--                    [style.&#45;&#45;bg]="themeList?.hovered_text_color + '20'"-->
<!--                    *appCheckPermission="['Admin', 'Master Admin', 'IT']"-->
<!--                    ngbTooltip="{{'CSR.CSR-LIST' | translate}}"-->
<!--                    placement="right">-->
<!--                  <a class="list-group-item list-group-item-action px-menu-4">-->
<!--                    <svg-icon src="assets/svg/icon-menu/carbon_request-quote.svg"-->
<!--                        [stretch]="true"-->
<!--                        [svgStyle]="{ 'fill': (rla.isActive ?-->
<!--                    themeList?.hovered_text_color: themeList?.text_color), 'height.px': 20, 'width.px': 20}">-->
<!--                    </svg-icon>-->
<!--                    <span class="pl-2 f-size-17"-->
<!--                        *ngIf="isFullSidebar"-->
<!--                        [ngStyle]="{-->
<!--                  color: rla.isActive-->
<!--                    ? themeList?.hovered_text_color-->
<!--                    : themeList?.text_color-->
<!--                }">-->
<!--                      {{'CSR.CSR-LIST' | translate}}-->
<!--                    </span>-->
<!--                  </a>-->
<!--                </div>-->
<!--              </ng-container>-->
<!--            </ng-container>-->

<!--            &lt;!&ndash; Manage Certificate &ndash;&gt;-->
<!--            <ng-container *ngIf="manageCertificate">-->
<!--              <div routerLinkActive="active"-->
<!--                  [routerLink]="['/', 'certificate']"-->
<!--                  #rla="routerLinkActive"-->
<!--                  [style.&#45;&#45;color]="themeList?.hovered_text_color"-->
<!--                  [style.&#45;&#45;bg]="themeList?.hovered_text_color + '20'"-->
<!--                  *appCheckPermission="['Admin', 'Master Admin', 'IT']"-->
<!--                  ngbTooltip="{{'SIDEBAR.CERTIFICATE' | translate}}"-->
<!--                  placement="right">-->
<!--                <a class="list-group-item list-group-item-action px-menu-4">-->
<!--                  <svg-icon src="assets/svg/icon-menu/certificate.svg"-->
<!--                      [stretch]="true"-->
<!--                      [svgStyle]="{-->
<!--              'fill': rla.isActive-->
<!--                ? themeList?.hovered_text_color-->
<!--                : themeList?.text_color,-->
<!--                'stroke': rla.isActive-->
<!--                ? themeList?.hovered_text_color-->
<!--                : themeList?.text_color,-->
<!--              'height.px': 20,-->
<!--              'width.px': 20-->
<!--            }">-->
<!--                  </svg-icon>-->
<!--                  <span class="pl-2 f-size-17"-->
<!--                      *ngIf="isFullSidebar"-->
<!--                      [ngStyle]="{-->
<!--              color: rla.isActive-->
<!--                ? themeList?.hovered_text_color-->
<!--                : themeList?.text_color-->
<!--              }">-->
<!--                    {{'SIDEBAR.CERTIFICATE'|translate}}-->
<!--                  </span>-->
<!--                </a>-->
<!--              </div>-->
<!--            </ng-container>-->
<!--          </div>-->
<!--        </ng-container>-->

        <!-- Usage Information -->
        <div class="custom-border"
          *appCheckPermission="['Admin', 'Master Admin']"
          [ngStyle]="{'background': usageInformation? '': '' , 'color': themeList?.text_color}">
          <div routerLinkActive="active"
            #rla="routerLinkActive"
            class="mt-2 hasSubMenu"
            *appCheckPermission="['Admin', 'Master Admin', 'IT']"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            placement="right"
            ngbTooltip="{{'SIDEBAR.USAGE-INFORMATION'|translate}}"
            (click)="usageInformation=!usageInformation">
            <a class="list-group-item list-group-item-action px-menu-4 position-relative">
              <svg-icon src="assets/svg/icon-menu/carbon_information.svg"
                class="mt-title-svg"
                [stretch]="true"
                [svgStyle]="{
                'fill': rla?.isActive
                  ? themeList?.hovered_text_color
                  : themeList?.text_color,
                'height.px': 20,
                'width.px': 20
              }">
              </svg-icon>
              <span class="w-100 f-size-17 pt-1 pl-2"
                *ngIf="isFullSidebar"
                [ngStyle]="{'color': themeList?.text_color}">{{"SIDEBAR.USAGE-INFORMATION"|translate}}</span>
              <div class="text-right pl-2 position-absolute position-arrow"
                [ngStyle]="{'color': themeList?.text_color}">
                <i class="fal"
                  [ngClass]=" usageInformation ? 'fa-angle-up' : 'fa-angle-down'"></i>
              </div>
            </a>
          </div>

          <!-- usage information -->
          <ng-container *ngIf="usageInformation">
            <ng-container *appFeatureFlag="'memo_enable'">
              <!-- Dashboard -->
              <div routerLinkActive="active"
                  [routerLink]="['/', 'kpi-dashboard']"
                  #rla="routerLinkActive"
                  [style.--color]="themeList?.hovered_text_color"
                  [style.--bg]="themeList?.hovered_text_color + '20'"
                  *appCheckPermission="['Admin', 'Master Admin']"
                  ngbTooltip="{{'SIDEBAR.DASHBOARD' | translate}}"
                  placement="right">
                <a class="list-group-item list-group-item-action px-menu-4">
                  <svg-icon src="assets/svg/icon-menu/dashboard.svg"
                      [stretch]="true"
                      [svgStyle]="{
                        'fill': rla.isActive
                          ? themeList?.hovered_text_color
                          : themeList?.text_color,
                        'height.px': 20,
                        'width.px': 20
                      }">
                  </svg-icon>
                  <span class="pl-2 f-size-17"
                      *ngIf="isFullSidebar"
                      [ngStyle]="{
                        color: rla.isActive
                          ? themeList?.hovered_text_color
                          : themeList?.text_color
                      }">
                    {{'SIDEBAR.DASHBOARD' | translate}}
                  </span>
                </a>
              </div>
            </ng-container>

            <!-- Usage Dashboard -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'usage-dashboard', 'usage']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin']"
                [ngbTooltip]="'SIDEBAR.Package-Usage'|translate"
                placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <svg-icon src="assets/svg/icon-menu/credit-usage.svg"
                    [stretch]="true"
                    [svgStyle]="{ 'fill': (rla.isActive ? themeList?.hovered_text_color: themeList?.text_color),
        'height.px': 20, 'width.px': 20}">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                    color: rla.isActive
                      ? themeList?.hovered_text_color
                      : themeList?.text_color
                  }">
                  {{'SIDEBAR.Package-Usage'|translate}}
                </span>
              </a>
            </div>

            <!-- Memo Report -->
            <div routerLinkActive="active"
              [routerLink]="['/', 'memo-report']"
              #rla="routerLinkActive"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              *appCheckPermission="['Admin', 'Master Admin']"
              ngbTooltip="{{'SIDEBAR.MEMO-REPORT' | translate}}"
              placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <svg-icon src="assets/svg/icon-menu/text-link--analysis.svg"
                  [stretch]="true"
                  [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.MEMO-REPORT' | translate}}
                </span>
              </a>
            </div>

            <!-- Operation Log -->
            <div routerLinkActive="active"
              [routerLink]="['/', 'operation-log']"
              #rla="routerLinkActive"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              *appCheckPermission="['Admin', 'Master Admin']"
              ngbTooltip="{{'SIDEBAR.OPERATION-LOG' | translate}}"
              placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <svg-icon src="assets/svg/icon-menu/task--view.svg"
                  [stretch]="true"
                  [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.OPERATION-LOG' | translate}}
                </span>
              </a>
            </div>
          </ng-container>
        </div>

         <!-- eContract Information -->
        <ng-container *appFeatureFlag="'econtract_enable'">
            <div class="custom-border"
            *appCheckPermission="['Admin', 'Master Admin']"
            [ngStyle]="{'background': eContract? '': '' , 'color': themeList?.text_color}">
          <div routerLinkActive="active"
              #rla="routerLinkActive"
              class="mt-2 hasSubMenu"
              *appCheckPermission="['Admin', 'Master Admin', 'IT']"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              placement="right"
              ngbTooltip="{{'SIDEBAR.USAGE-INFORMATION'|translate}}"
              (click)="eContract=!eContract">
            <a class="list-group-item list-group-item-action px-menu-4 position-relative">
              <svg-icon src="assets/svg/icon-menu/mail-attachment.svg"
                  class="mt-title-svg"
                  [stretch]="true"
                  [svgStyle]="{
                'fill': rla?.isActive
                  ? themeList?.hovered_text_color
                  : themeList?.text_color,
                'height.px': 16,
                'width.px': 16
              }">
              </svg-icon>
              <span class="w-100 f-size-17 pt-1 pl-2"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{'color': themeList?.text_color}">{{"SIDEBAR.e-Contract"|translate}}</span>
              <div class="text-right pl-2 position-absolute position-arrow"
                  [ngStyle]="{'color': themeList?.text_color}">
                <i class="fal"
                    [ngClass]=" eContract ? 'fa-angle-up' : 'fa-angle-down'"></i>
              </div>
            </a>
          </div>

          <!-- eContract information -->
          <ng-container *ngIf="eContract">
            <!-- Contract Package -->
            <div routerLinkActive="active"
            [routerLink]="['/', 'contract-package','usage']"
            #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin']"
                ngbTooltip="{{'SIDEBAR.DASHBOARD' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <svg-icon src="assets/svg/icon-menu/document--signed.svg"
                    [stretch]="true"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.Contract Package' | translate}}
                </span>
              </a>
            </div>

            <!-- Contract Dashboard -->
            <div routerLinkActive="active"
            [routerLink]="['/', 'contract-package','kpi']"
            #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin']"
                [ngbTooltip]="'SIDEBAR.Contract Dashboard'|translate"
                placement="right">
              <a class="list-group-item list-group-item-action px-menu-4">
                <svg-icon src="assets/svg/icon-menu/document--view.svg"
                    [stretch]="true"
                    [svgStyle]="{ 'fill': (rla.isActive ? themeList?.hovered_text_color: themeList?.text_color),
        'height.px': 20, 'width.px': 20}">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                    color: rla.isActive
                      ? themeList?.hovered_text_color
                      : themeList?.text_color
                  }">
                  {{'SIDEBAR.Contract Dashboard'|translate}}
                </span>
              </a>
            </div>
          </ng-container>
        </div>
      </ng-container>

        <!-- Memo -->
      <ng-container *appFeatureFlag="'memo_enable'">
        <div routerLinkActive="active"
            [routerLink]="['/', 'memos']"
            #rla="routerLinkActive"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            *appCheckPermission="['General', 'Administrator', 'Official Letter Admin', 'Contract Management']"
            ngbTooltip="{{'SIDEBAR.MEMOS' | translate}}"
            placement="right">
          <a class="list-group-item list-group-item-action px-menu-4">
            <!-- Manage Users -->
            <svg-icon src="assets/svg/icon-menu/memo.svg"
              [stretch]="true"
              [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
            </svg-icon>
            <span class="pl-2 f-size-17"
              *ngIf="isFullSidebar"
              [ngStyle]="{
                  color: rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              {{'SIDEBAR.MEMOS' | translate}}
            </span>
          </a>
        </div>
      </ng-container>

      <ng-container *appFeatureFlag="'econtract_enable'">
        <!-- contract list -->
        <div routerLinkActive="active"
            [routerLink]="['/', 'contract']"
            #rla="routerLinkActive"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            *appCheckPermission="['Contract Management']"
            ngbTooltip="{{'SIDEBAR.Contract-list' | translate}}"
            placement="right">
          <a class="list-group-item list-group-item-action px-menu-4">
            <svg-icon src="assets/svg/icon-menu/memo.svg"
                [stretch]="true"
                [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
            </svg-icon>
            <span class="pl-2 f-size-17"
                *ngIf="isFullSidebar"
                [ngStyle]="{
                  color: rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              {{'SIDEBAR.Contract-list' | translate}}
            </span>
          </a>
        </div>
      </ng-container>

        <!-- Config -->
        <div routerLinkActive="active"
          [routerLink]="['/', 'configuration']"
          #rla="routerLinkActive"
          [style.--color]="themeList?.hovered_text_color"
          [style.--bg]="themeList?.hovered_text_color + '20'"
          *appCheckPermission="['Admin', 'Master Admin']"
          ngbTooltip="{{'SIDEBAR.CONFIGURATION' | translate}}"
          placement="right">
          <a class="list-group-item list-group-item-action px-menu-4">
            <svg-icon src="assets/svg/icon-menu/setting-theme.svg"
              [stretch]="true"
              [svgStyle]="{
                  'fill': rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
            </svg-icon>
            <span class="pl-2 f-size-17"
              *ngIf="isFullSidebar"
              [ngStyle]="{
                  color: rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              {{'SIDEBAR.CONFIGURATION' | translate}}
            </span>
          </a>
        </div>
      </div>

      <!-- in-out memo sidebar tab-->
      <div *ngIf="isInOutRoute"
        class="menu-main overflow-auto"
        [style.--padding]="(isFullSidebar? '1.5':'0.5') + 'rem'">

        <!-- In-Out Memo -->
        <div [class.active]="isInOutMemoListRoute"
          class="mt-2"
          [routerLink]="['/in-out']"
          [style.--color]="themeList?.hovered_text_color"
          [style.--bg]="themeList?.hovered_text_color + '20'"
          *appCheckPermission="['General', 'Administrator', 'Official Letter Admin']"
          ngbTooltip="Official Letter"
          placement="right">
          <a class="list-group-item list-group-item-action px-menu-4">
            <!-- Manage Users -->
            <svg-icon src="assets/svg/icon-menu/memo.svg"
              [stretch]="true"
              [svgStyle]="{
                  'fill': isInOutMemoListRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
            </svg-icon>
            <span class="pl-2 f-size-17"
              *ngIf="isFullSidebar"
              [ngStyle]="{
                  color: isInOutMemoListRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              Official Letter
            </span>
          </a>
        </div>

        <!-- Announcement -->
        <div [class.active]="isInOutAnnouncementRoute"
          [routerLink]="['/in-out/announcement']"
          class="custom-border"
          [style.--color]="themeList?.hovered_text_color"
          [style.--bg]="themeList?.hovered_text_color + '20'"
          ngbTooltip="Announcement"
          placement="right">
          <a class="list-group-item list-group-item-action notification px-menu-4">
            <svg-icon src="assets/svg/icon-menu/blog.svg"
              [svgStyle]="{
                'fill': isInOutAnnouncementRoute
                  ? themeList?.hovered_text_color
                  : themeList?.text_color,
                'height.px': 20,
                'width.px': 20
              }">
            </svg-icon>
            <span class="pl-2 f-size-17"
              *ngIf="isFullSidebar"
              [ngStyle]="{
                color: isInOutAnnouncementRoute
                  ? themeList?.hovered_text_color
                  : themeList?.text_color
              }">
            Announcement
          </span>
          <span class="badge-number d-inline-block"
                *ngIf="notificationService.inoutNeedAckCount > 0">
            {{ notificationService.inoutNeedAckCount > 99 ? '99+' : notificationService.inoutNeedAckCount }}
          </span>
          </a>
        </div>

        <div class="custom-border"
          *appCheckPermission="['General', 'Administrator', 'Official Letter Admin']"
          [ngStyle]="{'background': '' , 'color': themeList?.text_color}">
          <!-- Approval group header -->
          <div class="mt-2 hasSubMenu"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            placement="right"
            ngbTooltip="Assign Required"
            (click)="expandAssigneeGroup=!expandAssigneeGroup">
            <a class="list-group-item list-group-item-action px-menu-4 position-relative">
              <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                class="mt-title-svg"
                [stretch]="true"
                [svgStyle]="{
                'fill': themeList?.text_color,
                'height.px': 20,
                'width.px': 20
              }">
              </svg-icon>
              <span class="w-100 f-size-17 pt-1 pl-2"
                *ngIf="isFullSidebar"
                [ngStyle]="{'color': themeList?.text_color}">Assign Required</span>
              <div class="text-right pl-2 position-absolute position-arrow"
                [ngStyle]="{'color': themeList?.text_color}">
                <i class="fal"
                  [ngClass]=" expandAssigneeGroup ? 'fa-angle-up' : 'fa-angle-down'"></i>
              </div>
            </a>
          </div>

          <ng-container *ngIf="expandAssigneeGroup">
            <!-- Overdue -->
            <div [class.active]="isAssignAssigneeOverDueRoute"
              [routerLink]="['/in-out/pending-action/assign-assignee']"
              [queryParams]="{type: 'overdue'}"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              *appCheckPermission="['General', 'Administrator', 'Official Letter Admin']"
              ngbTooltip="Overdue"
              placement="right">
              <a class="list-group-item list-group-item-action notification px-menu-4">
                <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                  [stretch]="true"
                  [svgStyle]="{
                  'fill': isAssignAssigneeOverDueRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{
                  color: isAssignAssigneeOverDueRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              Overdue
            </span>
                <span class="badge-number d-inline-block"
                  *ngIf="notificationService.assignAssigneeOverDueCount > 0">
              {{ notificationService.assignAssigneeOverDueCount > 99 ? '99+' : notificationService.assignAssigneeOverDueCount }}
            </span>
              </a>
            </div>

            <!-- Due by Today -->
            <div [class.active]="isAssignAssigneeTodayDueRoute"
              [routerLink]="['/in-out/pending-action/assign-assignee']"
              [queryParams]="{type: 'today'}"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              *appCheckPermission="['General', 'Administrator', 'Official Letter Admin']"
              ngbTooltip="Due by Today"
              placement="right">
              <a class="list-group-item list-group-item-action notification px-menu-4">
                <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                  [stretch]="true"
                  [svgStyle]="{
                  'fill': isAssignAssigneeTodayDueRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{
                  color: isAssignAssigneeTodayDueRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              Due by Today
            </span>
                <span class="badge-number d-inline-block"
                  *ngIf="notificationService.assignAssigneeTodayDueCount > 0">
              {{ notificationService.assignAssigneeTodayDueCount > 99 ? '99+' : notificationService.assignAssigneeTodayDueCount }}
            </span>
              </a>
            </div>

            <!-- Next Due -->
            <div [routerLink]="['/in-out/pending-action/assign-assignee']"
              [queryParams]="{type: 'nextdue'}"
              [class.active]="isAssignAssigneeNextDueRoute"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              *appCheckPermission="['General', 'Administrator', 'Official Letter Admin']"
              ngbTooltip="Next Due"
              placement="right">
              <a class="list-group-item list-group-item-action notification px-menu-4">
                <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                  [stretch]="true"
                  [svgStyle]="{
                  'fill': isAssignAssigneeNextDueRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{
                  color: isAssignAssigneeNextDueRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              Next Due
            </span>
                <span class="badge-number d-inline-block"
                  *ngIf="notificationService.assignAssigneeNextDueCount > 0">
              {{ notificationService.assignAssigneeNextDueCount > 99 ? '99+' : notificationService.assignAssigneeNextDueCount }}
            </span>
              </a>
            </div>
          </ng-container>
        </div>

        <!-- Incoming Pending Action-->
        <div class="custom-border"
          [class.active]="isActionRequireRoute"
          [routerLink]="['/in-out/pending-action/action-require']"
          [style.--color]="themeList?.hovered_text_color"
          [style.--bg]="themeList?.hovered_text_color + '20'"
          *appCheckPermission="['General', 'Administrator', 'Official Letter Admin']"
          ngbTooltip="Action Required"
          placement="right">
          <a class="list-group-item list-group-item-action notification px-menu-4">
            <!-- Manage Users -->
            <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
              [stretch]="true"
              [svgStyle]="{
                      'fill': isActionRequireRoute
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
            </svg-icon>
            <span class="pl-2 f-size-17"
              *ngIf="isFullSidebar"
              [ngStyle]="{
                  color: isActionRequireRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              Action Required
            </span>
            <span class="badge-number d-inline-block"
              *ngIf="notificationService.actionRequireCount > 0">
              {{ notificationService.actionRequireCount > 99 ? '99+' : notificationService.actionRequireCount }}
            </span>
          </a>
        </div>

        <div class="custom-border"
          *appCheckPermission="['General', 'Administrator', 'Official Letter Admin']"
          [ngStyle]="{'background': '' , 'color': themeList?.text_color}">
          <!-- Approval group header -->
          <div class="mt-2 hasSubMenu"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            placement="right"
            ngbTooltip="Approval Request"
            (click)="expandOutgoingApprovalGroup=!expandOutgoingApprovalGroup">
            <a class="list-group-item list-group-item-action px-menu-4 position-relative">
              <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                class="mt-title-svg"
                [stretch]="true"
                [svgStyle]="{
                'fill': themeList?.text_color,
                'height.px': 20,
                'width.px': 20
              }">
              </svg-icon>
              <span class="w-100 f-size-17 pt-1 pl-2"
                *ngIf="isFullSidebar"
                [ngStyle]="{'color': themeList?.text_color}">Approval Request</span>
              <div class="text-right pl-2 position-absolute position-arrow"
                [ngStyle]="{'color': themeList?.text_color}">
                <i class="fal"
                  [ngClass]=" expandOutgoingApprovalGroup ? 'fa-angle-up' : 'fa-angle-down'"></i>
              </div>
            </a>
          </div>

          <ng-container *ngIf="expandOutgoingApprovalGroup">
            <!-- Overdue -->
            <div [class.active]="isOutgoingApprovalOverDueRoute"
              [routerLink]="['/in-out/pending-action/approval-request']"
              [queryParams]="{type: 'overdue'}"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              *appCheckPermission="['General', 'Administrator', 'Official Letter Admin']"
              ngbTooltip="Overdue"
              placement="right">
              <a class="list-group-item list-group-item-action notification px-menu-4">
                <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                  [stretch]="true"
                  [svgStyle]="{
                  'fill': isOutgoingApprovalOverDueRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{
                  color: isOutgoingApprovalOverDueRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              Overdue
            </span>
                <span class="badge-number d-inline-block"
                  *ngIf="notificationService.outgoingApprovalOverdueCount > 0">
              {{ notificationService.outgoingApprovalOverdueCount > 99 ? '99+' : notificationService.outgoingApprovalOverdueCount }}
            </span>
              </a>
            </div>

            <!-- Due by Today -->
            <div [class.active]="isOutgoingApprovalTodayDueRoute"
              [routerLink]="['/in-out/pending-action/approval-request']"
              [queryParams]="{type: 'today'}"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              *appCheckPermission="['General', 'Administrator', 'Official Letter Admin']"
              ngbTooltip="Due by Today"
              placement="right">
              <a class="list-group-item list-group-item-action notification px-menu-4">
                <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                  [stretch]="true"
                  [svgStyle]="{
                  'fill': isOutgoingApprovalTodayDueRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{
                  color: isOutgoingApprovalTodayDueRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              Due by Today
            </span>
                <span class="badge-number d-inline-block"
                  *ngIf="notificationService.outgoingApprovalTodayDueCount > 0">
              {{ notificationService.outgoingApprovalTodayDueCount > 99 ? '99+' : notificationService.outgoingApprovalTodayDueCount }}
            </span>
              </a>
            </div>

            <!-- Next Due -->
            <div [routerLink]="['/in-out/pending-action/approval-request']"
              [queryParams]="{type: 'nextdue'}"
              [class.active]="isOutgoingApprovalNextDueRoute"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              *appCheckPermission="['General', 'Administrator', 'Official Letter Admin']"
              ngbTooltip="Next Due"
              placement="right">
              <a class="list-group-item list-group-item-action notification px-menu-4">
                <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                  [stretch]="true"
                  [svgStyle]="{
                  'fill': isOutgoingApprovalNextDueRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{
                  color: isOutgoingApprovalNextDueRoute
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              Next Due
            </span>
                <span class="badge-number d-inline-block"
                  *ngIf="notificationService.outgoingApprovalNextDueCount > 0">
              {{ notificationService.outgoingApprovalNextDueCount > 99 ? '99+' : notificationService.outgoingApprovalNextDueCount }}
            </span>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <!-- Page Content -->
  <div id="page-content-wrapper"
      class="content-height">
    <app-navbar [profile]="profile"></app-navbar>

<!--    <ng-container *appFeatureFlag="'ddoc'">-->
<!--      <div class="col-12 px-0"-->
<!--          *ngIf="showWarning &&-->
<!--            profile?.otp_type === null &&-->
<!--            (checkRouteActive('/pending-approval') ||-->
<!--              checkRouteActive('/news-feed') ||-->
<!--              checkRouteActive('/memos') ||-->
<!--              checkRouteActive('/contract'))">-->
<!--        <div class="box-warning col-12 d-flex">-->
<!--          <i class="fal fa-exclamation-triangle align-self-center mr-2"-->
<!--              style="color:rgb(216, 187, 24)"></i>-->
<!--          <span class="align-self-center">{{ 'CERTIFICATE.OTP-NOT-ENABLE'|translate }}</span>-->
<!--          <button class="btn align-self-center ml-3"-->
<!--              [routerLink]="['/', 'profile-detail', 'otp']"-->
<!--              appSetTheme-->
<!--              type="button">-->
<!--            <span style="font-size: 13px">{{'CERTIFICATE.GOTO-OTP-SETTINGS'|translate}}</span>-->
<!--          </button>-->
<!--          <a class="text-black p-2 align-self-center ml-auto"-->
<!--              href="javascript:void(0)"-->
<!--              role="button"-->
<!--              (click)="showWarning = false">-->
<!--            <i class="fal fa-times"></i>-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
<!--    </ng-container>-->

    <div class="container-fluid overflow-auto p-0">
      <div class="container">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
